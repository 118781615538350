.content-container {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  .content-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .content-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .content-container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .tabpanel {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  .tabpanel::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .tabpanel::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .tabpanel::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .content-container {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  .content-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .content-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .content-container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .tabpanel {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  .tabpanel::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .tabpanel::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .tabpanel::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }