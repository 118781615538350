.icon {
  color: white; /* Màu sắc ban đầu của icon */
  transition: all 0.3s ease; /* Thêm transition cho hiệu ứng mượt mà */
}

.icon:hover {
  transform: scale(1.2); /* Phóng to icon khi hover */
  animation: sparkle 1s infinite ease-in-out, rotate 2s linear infinite; /* Áp dụng animation lấp lánh và xoay */
}

@keyframes sparkle {
  0%, 100% {
    text-shadow: 
      -4px -4px 8px #fff, 4px 4px 8px #fff, /* Bóng đổ trắng */
      -8px -8px 16px #ff2d95, 8px 8px 16px #ff2d95, /* Bóng đổ hồng */
      -12px -12px 24px #00ffff, 12px 12px 24px #00ffff; /* Bóng đổ xanh lơ */
    filter: brightness(1); /* Độ sáng bình thường */
  }
  50% {
    text-shadow: 
      -2px -2px 4px #fff, 2px 2px 4px #fff, /* Bóng đổ trắng nhỏ hơn */
      -4px -4px 8px #ff2d95, 4px 4px 8px #ff2d95, /* Bóng đổ hồng nhỏ hơn */
      -6px -6px 12px #00ffff, 6px 6px 12px #00ffff; /* Bóng đổ xanh lơ nhỏ hơn */
    filter: brightness(2); /* Tăng độ sáng lên 2 lần */
  }
}

@keyframes rotate { 
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

