.embla {
  max-width: 100%;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 60%;
}
.embla__viewport {
  overflow: hidden;
  position: relative;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  position: relative;
  
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 5rem;
  height: 5rem;
  z-index: 3;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__button--prev {
  position: absolute;
  top: 50%;
  left: 2%;
}
.embla__button--next {
  position: absolute;
  top: 50%;
  right: 2%;
}
